import request from '@/utils/request'
const serivceBase = require('@/config/serviceConfig')

// 地区列表
export function getAreaList(data) {
  return request({
    url: `${serivceBase.BMS}/systemDictionaryArea/selectSystemDictionaryArea`,
    method: 'post',
    data
  })
}
// 实物兑换
export function redeemPhysical(data) {
  return request({
    url: `${serivceBase.IMS}/physicalRedeemCode/redeem`,
    method: 'post',
    data
  })
}
// 实物查询物流信息
export function getLogistics(data) {
  return request({
    url: `${serivceBase.IMS}/physicalRedeemCode/getLogistics`,
    method: 'post',
    data
  })
}
