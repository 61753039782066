<template>
  <van-popup
    class="error-popup"
    v-model="visible"
    :close-on-click-overlay="false"
  >
    <div class="title">
      {{
        [1].includes(errType)
          ? '兑换失败'
          : [2].includes(errType)
          ? '兑换成功'
          : '使用说明'
      }}
    </div>
    <div class="content">
      <img
        class="icon"
        v-show="[1, 2].includes(errType)"
        src="@/assets/img/PhysicalRedeemCode/gift.png"
      />
      <div class="text" v-show="[2].includes(errType)">
        <p>1、商品在5个工作日内寄出，请耐心等待；</p>
        <p>2、兑换成功可在“兑换记录”查看物流信息；</p>
      </div>
      <div class="text" v-show="[1].includes(errType)">
        {{
          errMsg ||
            '兑换码不存在或已使用。若兑换码已使用，可在“兑换记录”中查看物流信息。'
        }}
      </div>
      <div class="text useText" v-show="[3].includes(errType)">
        <div>
          <span> 1、请输入正确兑换码及收件信息，</span>
          信息一经提交将无法退换或修改。
        </div>
        <div>
          <span> 2、兑换成功后商品将在</span>
          5个工作内<span>寄出，</span>因用户提供信息错误造成的损失由用户自行承担。
        </div>
        <div><span>3、可在“物流查询”页使用兑换码查看物流信息。</span></div>
      </div>
      <div class="footer" @click="$emit('input', false)">确定</div>
    </div>
  </van-popup>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    // 1错误，2正确，3提示
    errType: {
      type: Number,
      default: 1
    },
    errMsg: {
      type: String,
      default: undefined
    }
  },
  watch: {
    value(val) {
      this.visible = val
    }
  },
  data() {
    return {
      visible: this.value
    }
  }
}
</script>

<style lang="less" scoped>
.error-popup {
  width: 600px;
  background-color: transparent;
  .title {
    width: 600px;
    height: 180px;
    line-height: 180px;
    text-align: center;
    color: #212224;
    font-size: 36px;
    font-weight: bold;
    margin: 0 auto -1px;
    font-family: Source Han Sans CN;
    background-image: url('~@/assets/img/PhysicalRedeemCode/msgBg.png');
    background-size: 100% 100%;
  }
  .content {
    text-align: center;
    padding: 0 40px 60px;
    background-color: #fff;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    .icon {
      width: 184px;
      margin: 0px auto;
    }
    .text {
      background: #f7fafe;
      border-radius: 20px;
      padding: 45px 30px 56px;
      font-size: 24px;
      color: #367aeb;
      line-height: 40px;
      text-align: left;
      span {
        color: #212224;
      }
    }
    .useText > div {
      margin-bottom: 20px;
    }
    .footer {
      width: 260px;
      margin: 40px auto 0;
      height: 74px;
      line-height: 74px;
      background: #367aeb;
      border-radius: 37px;
      font-size: 28px;
      color: #ffffff;
      text-align: center;
    }
  }
}
</style>
