<template>
  <div class="content">
    <div
      class="tips"
      @click="$router.push({ name: 'PhysicalLogisticsEnquiry' })"
    >
      物流查询
    </div>
    <div class="tips useTips" @click=";(msgType = 3), (showMsg = true)">
      使用说明
    </div>
    <van-form class="ydform" ref="form" @submit="submit">
      <van-field
        class="topField"
        placeholder="请输入实物兑换码"
        v-model="form.redeemCodeNo"
        :leftIcon="require('@/assets/img/PhysicalRedeemCode/fied1.png')"
        :rules="[
          {
            required: true,
            message: '请输入实物兑换码'
          }
        ]"
      />
      <van-field
        placeholder="请输入收件人"
        :leftIcon="require('@/assets/img/PhysicalRedeemCode/fied2.png')"
        v-model="form.consignee"
        :rules="[
          {
            required: true,
            message: '请输入收件人'
          }
        ]"
      />
      <van-field
        placeholder="请输入收货手机"
        :leftIcon="require('@/assets/img/PhysicalRedeemCode/fied3.png')"
        v-model="form.consigneePhone"
        :rules="[
          {
            required: true,
            validator: val => /^1[3|4|5|6|7|8|9][0-9]{9}$/.test(val),
            message: '请填写正确的收货手机号'
          }
        ]"
      />
      <van-field
        placeholder="请选择所在地区"
        :leftIcon="require('@/assets/img/PhysicalRedeemCode/fied4.png')"
        v-model="form.consigneeRegion"
        readonly
        is-link
        @click="showRegion = true"
        :rules="[
          {
            required: true,
            message: '请选择所在地区'
          }
        ]"
      />
      <van-field
        placeholder="请输入详细地址"
        :leftIcon="require('@/assets/img/PhysicalRedeemCode/fied4.png')"
        v-model="form.consigneeAddress"
        :maxlength="30"
        :rules="[
          {
            required: true,
            message: '请输入详细地址'
          }
        ]"
      />
      <van-field
        class="botField"
        placeholder="请输入备注内容（可选）"
        :leftIcon="require('@/assets/img/PhysicalRedeemCode/fied5.png')"
        v-model="form.remarks"
      />
    </van-form>
    <img
      class="submitBut"
      @click="$refs.form.submit()"
      src="@/assets/img/PhysicalRedeemCode/submitBut.png"
    />
    <van-popup v-model="showRegion" round position="bottom">
      <van-cascader
        v-model="regionValue"
        title="请选择所在地区"
        :options="regionList"
        :field-names="{
          text: 'name',
          value: 'code',
          children: 'children'
        }"
        active-color="#FFA02F"
        @close="showRegion = false"
        @finish="onFinish"
      />
    </van-popup>
    <msgModalVue v-model="showMsg" :errType="msgType" :errMsg="errMsg" />
  </div>
</template>
<script>
import { getAreaList, redeemPhysical } from '@/api/PhysicalRedeemCode.js'
import msgModalVue from './msgModal.vue'
export default {
  components: { msgModalVue },
  data() {
    return {
      form: {},
      loading: false,
      showRegion: false,
      regionList: [],
      regionValue: undefined,
      showMsg: false,
      msgType: null,
      errMsg: undefined
    }
  },
  mounted() {
    this.loadAreaList()
  },
  methods: {
    async loadAreaList() {
      const { data } = await getAreaList()
      this.regionList = data
    },
    onFinish({ selectedOptions }) {
      this.form.consigneeRegion = selectedOptions
        .map(option => option.name)
        .join('/')
      this.showRegion = false
    },
    submit() {
      this.$refs.form.validate().then(async val => {
        const form = this.form
        const { code, message } = await redeemPhysical({
          ...form,
          consigneeAddress: `${form.consigneeRegion}/${form.consigneeAddress}`
        })
        if ([0].includes(code)) {
          this.msgType = 2
          this.form = {}
          this.showMsg = true
        } else {
          this.msgType = 1
          this.errMsg = message
          this.showMsg = true
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  min-height: 100vh;
  background: url('~@/assets/img/PhysicalRedeemCode/bg.png') top center
    no-repeat;
  background-size: contain;
  background-color: #eef3ff;
  position: relative;
  overflow: hidden;
  .tips {
    position: absolute;
    right: 0;
    top: 76px;
    width: 52px;
    height: 162px;
    background: url('~@/assets/img/PhysicalRedeemCode/tipBg.png');
    background-size: contain;
    writing-mode: vertical-rl;
    font-size: 24px;
    color: #ffffff;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .useTips {
    top: 260px;
  }
  .ydform {
    margin: 433px 40px 40px;
    box-shadow: 0px 14px 50px 0px rgba(131, 169, 203, 0.2);
    border-radius: 30px;
    padding: 50px 35px 20px;
    background: #fff;
    .van-cell {
      line-height: normal;
      align-items: center;
    }
    :deep(.van-field__left-icon) {
      margin-right: 15px;
    }
    :deep(.van-field--error .van-field__control::placeholder) {
      color: #ccc;
      -webkit-text-fill-color: currentColor;
    }
    :deep(.van-field--error .van-field__control, ) {
      color: #323233;
      -webkit-text-fill-color: currentColor;
    }
    .van-field {
      border: solid 1px #dae0e6;
      margin-bottom: 30px;
      border-radius: 10px;
    }
  }
  .submitBut {
    width: 90%;
    display: block;
    margin: auto;
  }
}
</style>
