var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"tips",on:{"click":function($event){return _vm.$router.push({ name: 'PhysicalLogisticsEnquiry' })}}},[_vm._v(" 物流查询 ")]),_c('div',{staticClass:"tips useTips",on:{"click":function($event){;(_vm.msgType = 3), (_vm.showMsg = true)}}},[_vm._v(" 使用说明 ")]),_c('van-form',{ref:"form",staticClass:"ydform",on:{"submit":_vm.submit}},[_c('van-field',{staticClass:"topField",attrs:{"placeholder":"请输入实物兑换码","leftIcon":require('@/assets/img/PhysicalRedeemCode/fied1.png'),"rules":[
        {
          required: true,
          message: '请输入实物兑换码'
        }
      ]},model:{value:(_vm.form.redeemCodeNo),callback:function ($$v) {_vm.$set(_vm.form, "redeemCodeNo", $$v)},expression:"form.redeemCodeNo"}}),_c('van-field',{attrs:{"placeholder":"请输入收件人","leftIcon":require('@/assets/img/PhysicalRedeemCode/fied2.png'),"rules":[
        {
          required: true,
          message: '请输入收件人'
        }
      ]},model:{value:(_vm.form.consignee),callback:function ($$v) {_vm.$set(_vm.form, "consignee", $$v)},expression:"form.consignee"}}),_c('van-field',{attrs:{"placeholder":"请输入收货手机","leftIcon":require('@/assets/img/PhysicalRedeemCode/fied3.png'),"rules":[
        {
          required: true,
          validator: val => /^1[3|4|5|6|7|8|9][0-9]{9}$/.test(val),
          message: '请填写正确的收货手机号'
        }
      ]},model:{value:(_vm.form.consigneePhone),callback:function ($$v) {_vm.$set(_vm.form, "consigneePhone", $$v)},expression:"form.consigneePhone"}}),_c('van-field',{attrs:{"placeholder":"请选择所在地区","leftIcon":require('@/assets/img/PhysicalRedeemCode/fied4.png'),"readonly":"","is-link":"","rules":[
        {
          required: true,
          message: '请选择所在地区'
        }
      ]},on:{"click":function($event){_vm.showRegion = true}},model:{value:(_vm.form.consigneeRegion),callback:function ($$v) {_vm.$set(_vm.form, "consigneeRegion", $$v)},expression:"form.consigneeRegion"}}),_c('van-field',{attrs:{"placeholder":"请输入详细地址","leftIcon":require('@/assets/img/PhysicalRedeemCode/fied4.png'),"maxlength":30,"rules":[
        {
          required: true,
          message: '请输入详细地址'
        }
      ]},model:{value:(_vm.form.consigneeAddress),callback:function ($$v) {_vm.$set(_vm.form, "consigneeAddress", $$v)},expression:"form.consigneeAddress"}}),_c('van-field',{staticClass:"botField",attrs:{"placeholder":"请输入备注内容（可选）","leftIcon":require('@/assets/img/PhysicalRedeemCode/fied5.png')},model:{value:(_vm.form.remarks),callback:function ($$v) {_vm.$set(_vm.form, "remarks", $$v)},expression:"form.remarks"}})],1),_c('img',{staticClass:"submitBut",attrs:{"src":require("@/assets/img/PhysicalRedeemCode/submitBut.png")},on:{"click":function($event){return _vm.$refs.form.submit()}}}),_c('van-popup',{attrs:{"round":"","position":"bottom"},model:{value:(_vm.showRegion),callback:function ($$v) {_vm.showRegion=$$v},expression:"showRegion"}},[_c('van-cascader',{attrs:{"title":"请选择所在地区","options":_vm.regionList,"field-names":{
        text: 'name',
        value: 'code',
        children: 'children'
      },"active-color":"#FFA02F"},on:{"close":function($event){_vm.showRegion = false},"finish":_vm.onFinish},model:{value:(_vm.regionValue),callback:function ($$v) {_vm.regionValue=$$v},expression:"regionValue"}})],1),_c('msgModalVue',{attrs:{"errType":_vm.msgType,"errMsg":_vm.errMsg},model:{value:(_vm.showMsg),callback:function ($$v) {_vm.showMsg=$$v},expression:"showMsg"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }